import { useEffect, useState } from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
// import { navigate } from 'gatsby'
import RenderExtensionLoader from 'src/utils/RenderExtensionLoader'
import { useAuth } from 'src/hooks/auth'

import storeConfig from '../../store.config'

const MY_ACCOUNT_PATH = '/account'
const MY_ACCOUNT_DIV_NAME = 'my-account'
const MY_ACCOUNT_EXTENSION_NAME = 'my-account-portal'
const ONE_MIN_IN_MILLI = 60 * 100

const render = async (locale: string) => {
  const loader = new RenderExtensionLoader({
    account: storeConfig.api.account,
    workspace: storeConfig.api.workspace,
    verbose: true,
    publicEndpoint: undefined,
    timeout: ONE_MIN_IN_MILLI,
    path: MY_ACCOUNT_PATH,
    locale,
  })

  const myAccountDiv = document.getElementById(MY_ACCOUNT_DIV_NAME)

  if ((window as any).__RENDER_7_RUNTIME__) {
    loader.render(MY_ACCOUNT_EXTENSION_NAME, myAccountDiv, undefined)

    return
  }

  await loader.load()
  ;(window as any).__RUNTIME__ = loader.render(
    MY_ACCOUNT_EXTENSION_NAME,
    myAccountDiv,
    undefined
  )
}

const MyAccount: React.FC = () => {
  const { user, isValidating } = useAuth()
  const isAuthenticated = !!user
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // if (isValidating) {
    //   return
    // }

    const challengeAndRender = async () => {
      try {
        // if (!isAuthenticated) {
        //   navigate('/login')

        //   return
        // }

        await render('pt-BR')
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    challengeAndRender()
  }, [isAuthenticated, isValidating])

  if (!isAuthenticated && !isValidating) {
    return null
  }

  return (
    <>
      <div id={MY_ACCOUNT_DIV_NAME} />
      {loading && 'loading...'}
    </>
  )
}

function Page() {
  return (
    <>
      <GatsbySeo noindex nofollow />

      <MyAccount />
    </>
  )
}

export default Page
